.layout {
  color: white;
  background: radial-gradient(
    at center,
    rgb(107, 124, 200),
    rgb(107, 185, 199)
  );
}

.h1 {
}

.about {
  margin: 20px 0;
}

.about > p {
  line-height: 30px;
  text-align: justify;
}

.news {
  display: flex;
  flex-direction: row;
  gap: 3em;
  margin: 30px auto;
}

.article {
  padding: 20px;
  border: 2px solid white;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 300px;
  box-shadow:
    0px 10px 10px rgba(255, 255, 255, 0.2),
    10px 0px 10px rgba(255, 255, 255, 0.2);
}

.article:hover {
  cursor: pointer;
  transform: scale(1.02);
  transform-origin: bottom;
}

.article > h6 {
  text-align: right;
}

.article > p:first-of-type {
  text-align: justify;
  height: 200px;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: normal;
}

.article > p:last-of-type {
  text-align: left;
}

@media only screen and (max-width: 1032px) {
  .news {
    flex-direction: column;
  }
}
