.textarea {
  width: 100%;
  padding: 10px;
  border: 3px solid #6ab8c6;
  border-top-left-radius: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
}
.textarea:disabled {
  color: black;
  cursor: default;
  background-color: #e8f3f5;
}
