.section {
  width: 800px;
  margin: 20px 0;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  border-radius: 1em;
}

.card {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 4px solid #6ab8c6;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  transition: 0.15s;
  background-color: white;
}
.card strong {
  font-size: 20px;
  font-weight: bold;
}

.status {
  margin-left: auto;
  margin-right: 0px;
  padding: 10px;
  background-color: #6ab8c6;
  color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #6ab8c6;
}

.price {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 2em;
}

.items {
  display: none;
  background-color: white;
  padding: 20px;
  border: 4px solid #6ab8c6;
  margin-left: auto;
  margin-right: auto;
  border-top: 0px;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.card-checkbox {
  display: none;
}

.card-checkbox:checked ~ .items {
  display: flex;
}

.card-checkbox:checked ~ .card {
  display: flex;
  margin-bottom: 0px;
}

.card-checkbox:checked ~ .card svg {
  transform: rotate(180deg);
}

.table {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-spacing: 0px;
  border-collapse: separate;
}

.table tr:last-child > td {
  border-top: 2px solid #6ab8c6;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
}

.table th {
  font-weight: bold;
}

.nav-panel {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.nav-panel a {
  cursor: pointer;
}

.nav-panel a:hover,
.nav-panel-choosed {
  text-decoration: underline;
}

.card-activity {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media only screen and (max-width: 900px) {
  .nav-panel {
    flex-direction: column;
  }
}

@media only screen and (max-width: 830px) {
  .container {
    width: 100%;
  }
  .section {
    width: 100%;
  }
  .table {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .table,
  .table thead,
  .table tbody {
    display: flex;
    flex-direction: column;
  }

  .table th {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .table tr {
    display: grid;
    grid-template-areas:
      "title title"
      "amount price";
    text-align: center;
  }

  .table tr > th:nth-child(1),
  .table tr > td:nth-child(1) {
    grid-area: title;
  }

  .table tr > th:nth-child(2),
  .table tr > td:nth-child(2) {
    grid-area: amount;
    border-top: 0px;
  }

  .table tr > th:nth-child(3),
  .table tr > td:nth-child(3) {
    grid-area: price;
    border-top: 0px;
  }
}

.list-empty {
  margin-top: 20px;
  font-weight: bold;
  font-size: 24px;
  color: #2fb3ca;
  border: 2px solid #6ab8c6;
  padding: 20px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
  background: white;
  text-align: center;
}
