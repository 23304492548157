.container {
  max-width: 1280px;
}

.description {
  padding: 30px;
  border: 3px solid #6ab8c6;
  background-color: white;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
}

.img-wrapper {
  display: block;
  max-height: 500px;
  max-width: 500px;
  float: left;
  margin: 1px 20px 20px 0;
}

.img-wrapper img {
  max-height: 500px;
  max-width: 500px;
  border: 3px solid #6ab8c6;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
}

.content {
  line-height: 25px;
  text-align: justify;
  white-space: pre-line;
}

.date {
  margin-left: auto;
  margin-right: 0;
  color: grey;
}

.wrapper-link {
  margin: 40px 0px;
  margin-left: auto;
}

.wrapper-link > a {
  cursor: pointer;
  color: white;
  text-decoration: none;
  padding: 15px;
  font-size: 20px;
  background: #6ab8c6;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow:
    0px 10px 10px rgba(39, 105, 204, 0.2),
    10px 0px 10px rgba(39, 105, 204, 0.2);
}

.wrapper-link:hover {
  transform: scale(1.02);
  transform-origin: bottom;
}

.wrapper-link > a:hover {
  cursor: pointer;
  background-color: #55909b;
}

@media only screen and (max-width: 540px) {
  .img-wrapper {
    width: 100%;
    float: none;
    text-align: center;
    height: auto;
    margin: 0;
    margin-bottom: 20px;
  }
  .img-wrapper img {
    max-height: 400px;
    max-width: 300px;
  }
}
@media only screen and (max-width: 350px) {
  .img-wrapper img {
    max-height: 300px;
    max-width: 200px;
  }
}

.date-wrapper {
  display: flex;
  margin-top: 24px;
}
