.news {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 20px 0;
  padding: 20px;
  border: 4px solid #6ab8c6;
  border-radius: 20px;
  transition: 0.15s;
  background-color: rgb(248, 248, 248);
}

.news:hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: rgb(248, 248, 248);
  transform-origin: bottom;
}

.news > h6 {
  font-size: 20px;
  font-weight: bold;
  color: #2fb3ca;
}

.news > p {
  line-height: 30px;
}

.news > div {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.news > div > a {
  text-decoration: none;
}

.news > div > p:last-child {
  margin-left: auto;
}

.news-btn {
  margin: 30px 0;
  width: 1024px;
}

.news-btn > button {
  margin-left: 0;
  margin-right: auto;
}
